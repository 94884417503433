import React, {useEffect} from 'react';
import {Grid, Box} from '@material-ui/core';
import propTypes from 'prop-types';
import {theme} from '../../themes/default-theme';
import styled from 'styled-components';
import NextLink from '../../components/navigation/NextLink';
import ReactHtmlParser from 'react-html-parser';

import {RespImg} from '../../components/ui';
import Cta from '../../components/ui/Cta';
import IconLink from '../../components/ui/IconLink';

const PageContainer = styled.div`
  max-width: 1440px;
  font-weight: 700;
	margin: 0 auto;
	
	& .MuiPagination-root {
    ${props => props.paginationTopBorder &&
      `border-top: 1px solid ${theme.palette.middleGrey};`
    }
		margin-left: 16px;
		margin-right: 16px;
		padding-top: 30px;
	}

	& .MuiPagination-ul {
		width: max-content;
		margin: 0 auto;
	}
` 

const HeadingContainer = styled.div`
	text-align: center;
	max-width: 780px;
	margin-bottom: 48px;

  ${theme.breakpoints.down('sm')} {
    margin-bottom: 20px;
  }
` 

const CommunityGrid = styled(Grid)`
	${theme.breakpoints.down('sm')} {
		width: 100%;
		margin-bottom: 16px;
	}
  ${theme.breakpoints.up('md')} {
    margin-bottom: 24px;
  }
	
	& .box-child {
		${theme.breakpoints.up('md')} {
			height: 100%;
		}
	}

	a {
		width: 100%;
		text-decoration: none;
	}

	${theme.breakpoints.up('lg')} {
		padding-right: 3px;

		${props => props.spacing == 'large' &&`
			padding-right: 1px !important;
		`}
	}

	${props => props.regularTabletStyling && `
		${theme.breakpoints.up('md')} {
			padding-right: 3px;

			${ props.spacing == 'large' &&`
				padding-right: 1px !important;
			`}
		}
	`}

	
` 

const StyledGrid = styled(Grid)`
	padding-left: 20px;
	padding-right: 20px;
` 

const ThumbnailContainer = styled.div`
	position: relative;

	& img {
		object-fit: cover;
		
		${props => !props.regularTabletStyling && `
			${theme.breakpoints.only('md')} {
				width: 327px;
			}
		`}

		${theme.breakpoints.up('lg')} {
			min-height: 193px;

			${props => props.heightValue == 'normal' &&`
				max-height: 233px;
			`}

			${props => props.heightValue == 'large' &&`
				max-height: 304px;
			`}
		}
	}
` 

const Category = styled.div`
	position: absolute;
	background: ${theme.palette.vividBlue};
	left: 0px;
	top: 0px;
	color: white;
	padding: 3px 10px;
	color: #FFF;
	text-align: center;
	font-size: 12px;
	font-weight: 700;
	line-height: 21px;
	text-transform: capitalize;
` 

const CaptionContainer = styled.div`
		padding: 12px;
		margin-top: -4px;
		background: ${theme.palette.white};
		padding-left: 0;
		padding-right: 0;
		
		${props => !props.regularTabletStyling ? `
			${theme.breakpoints.only('md')} {
				width: 100%;
				height: max-content;
				padding: 0;
			}
		` : `
			${theme.breakpoints.only('md')} {
				padding-left: 10px;
				padding-right: 10px;
			}
		`}
		
		${theme.breakpoints.up('lg')} {
			padding: 26px;

			${props => props.heightValue == 'normal' &&`
				> div:first-child {
					min-height: 190px;
				}
			`}

			${props => props.heightValue == 'large' &&`
				> div:first-child {
					min-height: 170px;
				}
			`}
		}
  }
` 

const ArticleTitle = styled.div`
	text-align: center;
	font-size: 34px;
	font-weight: 800;
	line-height: 38px;
	text-transform: uppercase;
	color: ${theme.palette.charcoal};

	${theme.breakpoints.down('sm')} {
		font-size: 18px;
		line-height: 24px;
  }
` 

const CommunityTitle = styled.div`
  font-size: 18px;
	font-weight: 700;
	padding-bottom: 4px;
	color: ${theme.palette.charcoal};
	
	${theme.breakpoints.up('lg')} {
		min-height: 55px;
		overflow: hidden;
	}

  ${theme.breakpoints.down('sm')} {
		font-size: 14px;
		padding-bottom: 8px;
  }
` 

const IconLinkContainer = styled.div`
	display: block;
`

const CommunityDescription = styled.div`
  font-size: 16px;
	font-weight: 400;
	padding-bottom: 4px;
	color: ${theme.palette.gunmentalGrey};
	margin-top: 10px;
	margin-bottom: 10px;

	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;

  ${theme.breakpoints.down('sm')} {
		font-size: 14px;
  }
` 

const CommunityGridItem = styled.div`
  height: 100%;
  overflow: hidden;
`

const StyledLink = styled.a`
	${props => !props.regularTabletStyling && `
		${theme.breakpoints.only('md')} {
			display: flex;
			align-items: center;
			gap: 40px;
		}
	`}
`

const CtaContainer = styled.div`
	text-align: center;
	padding-left: 20px;
	padding-right: 20px;

	* {
		text-decoration: none;
		width: auto;
		min-width: 195px;
		margin: auto;
	}
`

const CommunityContainer = (props) => {

	const [end, setEnd] = React.useState(props.shallowCommunityPage ? 8 : props.communityPage ? 3 : props.query ? 6 : 11);
	
  	const handleChange = () => {
		setEnd(end + ((props.communityPage || props.query) ? 6 : 8));
	};

	const {title} = props;

  	return (
		<>
			<PageContainer>

				{title &&
					<Grid container className={'hasPadding'} spacing={0} direction={'row'} wrap={'wrap'} align={'center'}>
						<Grid item xs={12}>
							<HeadingContainer>
								<ArticleTitle dangerouslySetInnerHTML={{__html: title}} />
							</HeadingContainer>
						</Grid>
					</Grid>
				}
				<StyledGrid container className={'hasPadding'} spacing={0} direction={'row'} wrap="wrap">
					{props.articles && props.articles.slice(0, end).map(function(article, index) {
						console.log('article prop', article)
						return (
							<CommunityGrid 
								key={article.uri}
								item 
								sm={12} 
								md={props.articles.length === 4 ? 6 : 4} 
								lg={props.articles.length === 4 ? 3 : (props.shallowCommunityPage ? 3 : !(props.communityPage || props.query) && index > 2 ? 3 : 4)} 
								spacing={props.shallowCommunityPage ? 'normal' : !props.communityPage && !props.query && index > 2 ? 'normal' : 'large'}
								regularTabletStyling={props.regularTabletStyling}
							>
								<CommunityGridItem>
									<NextLink href={process.env.DEALER_SITE ? article.uri : props.path + article.uri}>
										<StyledLink regularTabletStyling={props.regularTabletStyling}>
											{article.img &&
												<ThumbnailContainer regularTabletStyling={props.regularTabletStyling} heightValue={props.shallowCommunityPage ? 'normal' : !props.communityPage && !props.query && index > 2 && props.articles.length !== 4 ? 'normal' : 'large'}>
													<RespImg
														{...article.img}
													/>
													{article.category && <Category dangerouslySetInnerHTML={{__html: article.category}} />}
												</ThumbnailContainer>
											}
											<CaptionContainer regularTabletStyling={props.regularTabletStyling} heightValue={props.shallowCommunityPage ? 'normal' : !props.communityPage && !props.query && index > 2 && props.articles.length !== 4 ? 'normal' : 'large'}>
												<div>
													{article.title && <CommunityTitle dangerouslySetInnerHTML={{__html: article.title}} />}
													{article.teaser && <CommunityDescription>{ReactHtmlParser(article.teaser)}</CommunityDescription>}
												</div>
												<IconLinkContainer>
													<IconLink colour="vividBlue" href={process.env.DEALER_SITE ? article.uri : props.path + article.uri} link={{ href: process.env.DEALER_SITE ? article.uri : props.path + article.uri, title: 'READ MORE' }} />
												</IconLinkContainer>
											</CaptionContainer>
										</StyledLink>
									</NextLink>
								</CommunityGridItem>
							</CommunityGrid>
						)
					})}
				</StyledGrid>
				{props.articles.length > end &&
					<CtaContainer>
						{props.communityPage ?
							<NextLink href={'/community/category/buying-guides'}>
								<a>
									<Cta 
										primary 
										outline
									>
										SEE ALL BUYING GUIDES
									</Cta>
								</a>
							</NextLink>
								:
							<Cta 
								onClick={handleChange}
								primary 
								outline
							>
								LOAD MORE
							</Cta>
						}
					</CtaContainer>
				}
				{props.cta &&
					<CtaContainer>
						<NextLink href={props.cta.href}>
							<a>
								<Cta 
                                    inline 
									outline
                                    // primary={props.cta.style === 'primary'}
                                    // secondary={props.cta.style === 'secondary'}
                                    // outline={props.cta.style === 'outline'}
                                >
                                    {props.cta.title}
                                </Cta>
							</a>
						</NextLink>

					</CtaContainer>
				}
			</PageContainer>
		</>
  )
}

CommunityContainer.propTypes = {
	path: propTypes.string,
	itemsPerPage: propTypes.number,
	paginationTopBorder: propTypes.bool,
	searchString: propTypes.string,
	communityPage: propTypes.bool,
	query: propTypes.bool,
	regularTabletStyling: propTypes.bool
}
  
CommunityContainer.defaultProps = {
	path: '/community/article/',
	itemsPerPage: 6,
	paginationTopBorder: true,
	searchString: null,
	communityPage: false,
	query: false,
	regularTabletStyling: false
}

export default CommunityContainer