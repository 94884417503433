import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {findIndex, get} from 'lodash';
import produce from 'immer';
import qs from 'qs';

import Accordion from '../../components/ui/NewAccordion';
import Checkbox from '../../components/forms/Checkbox';
import Slider from '../../components/ui/Slider';
import Text from '../../components/typography';
import {theme} from '../../themes/default-theme';
import { ButtonBase } from '@material-ui/core';

const CheckboxItem = styled.div`
  text-transform: uppercase;
  padding-left: 8px;

  .MuiFormGroup-root label > span {
    padding: 4px;
  }
`

const AccordionItem = styled.div` 
  margin-bottom: 1px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 18px;

  ${theme.breakpoints.up("md")} {
    padding-bottom: 32px;
  }

  h6 {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
    font-size: 15px;
    color: ${theme.palette.skyBlue};
  }
`

const SliderWrapper = styled.div`
  width: 100%;
`

const Deselect = styled(ButtonBase)`
  text-decoration: underline;
  margin-bottom: 16px;
`

const StockFilter = (props) => {
  const {
    filterItems,
    onUpdate,
    state,
    defaultFilters
  } = props;


  
  const [filters, updateFilters] = useState({
    types: [],
    categories: [],
    brands: [],
    models: []
  });

  useEffect(() => {
    if (defaultFilters && defaultFilters.models) {

      // const newState = produce(filters, draftState => {
      //   const newVal = filters['models'].map(item => {
      //     return {
      //       ...item,
      //       checked: defaultFilters.type === true
      //     }
      //   })
      //   draftState['type'].vals = Object.values({
      //     ...draftState['type'].vals,
      //     ...newVal
      //   })
      // }) 

      setFilters({
        ...filters,
        models: defaultFilters.models
      });
    }
    // if (defaultFilters && defaultFilters.type) {

    //   const newState = produce(filters, draftState => {
    //     const newVal = filters['type'].vals.map(item => {
    //       return {
    //         ...item,
    //         checked: defaultFilters.type === true
    //       }
    //     })
    //     draftState['type'].vals = Object.values({
    //       ...draftState['type'].vals,
    //       ...newVal
    //     })
    //   }) 

    //   setFilters(newState);
    // }

  }, [defaultFilters])

  const handleFilterChange = (accordion, type, event) => {
    const val = event.target.checked;
    const newState = (type === 'all' && val) ? produce(filters, draftState => {
      const newVal = filters[accordion].vals.map(item => {
        return {
          ...item,
          checked: true
        }
      })
      draftState[accordion].vals = Object.values({
        ...draftState[accordion].vals,
        ...newVal
      })
    }) 
    : produce(filters, draftState => {
        const newVal = draftState[accordion].vals.find(item => {
          if (item.value === type) {
            item.checked = val
          }
        })
        draftState[accordion].vals = Object.values({
          ...draftState[accordion].vals,
          ...newVal
        })
        const allIndex = findIndex(filters[accordion].vals, {'value': 'all'})
        draftState[accordion].vals[allIndex].checked = false
      })

      if (accordion === 'brands') {
        const newModelArr = getActiveModels(newState.brands.vals);
        setFilters({
          ...newState,
          models: newModelArr
        });
      } else {
        setFilters(newState);
      }

  }

  const handleModelChange = (type, event) => {
    const val = event.target.checked;
    const newState = produce(filters, draftState => {
      const modelIndex = findIndex(filters.models, {value: type});
      draftState.models[modelIndex].checked = val
    });
    setFilters(newState);
  }

  const setInitialFilters = () => {

    // types
    const types = {
      vals: filterItems.types.map(item => {
        return {
          ...item,
          checked: typeof(item.checked) !== 'undefined' ? item.checked : true
        }
      })
    }
  
    // categories
    const categories = {
      vals: filterItems.categories.map(item => {
        return {
          ...item,
          checked: true
        }
      })
    }

    // types
    const brands = {
      vals: filterItems.brands.map(item => {
        return {
          ...item,
          checked: true
        }
      })
    }
    
    // models
    const models = getActiveModels(brands.vals, true);
    var initialModels;
    const qsData = qs.parse(window.location.search.replace('?',''));

    if (qsData && qsData.search && qsData.search.series) {
        initialModels = models.map(item => {
          return {
            ...item,
            checked: item.value.toLowerCase() === qsData.search.series.toLowerCase() ? true : false
          }
        })
    } else {
      initialModels = models
    }

    console.log('stock filter', {initialModels, models})
    
    const filterProps = {
      types,
      categories,
      brands,
      models: initialModels,
      year: {
        min: filterItems.year.min, 
        max: filterItems.year.max,
        val: [filterItems.year.min, filterItems.year.max],
        key: Date.now()
      },
      price: {
        min: filterItems.price.min, 
        max: filterItems.price.max,
        val: [filterItems.price.min, filterItems.price.max],
        key: Date.now()
      }
    }

    setFilters(filterProps);

  }

  const handleSliderChange = (type, val) => {
    if (type === 'year') {
      setFilters({
        ...filters,
        year: {
          ...filters.year,
          val
        }
      })
    }
    if (type === 'price') {
      setFilters({
        ...filters,
        price: {
          ...filters.price,
          val
        }
      })
    }
  }

  const handleClearItem = (item) => {


    const objName = filters[item].vals ? filters[item].vals : filters[item]

    console.log('handleClearItem', objName, item)

    const newState = filters[item].vals ? {
      ...filters,
      [item]: {
        vals: objName.map(item => {
          return {
            ...item,
            checked: false
          } 
        })
      }
    } : {
      ...filters,
      [item]: objName.map(item => {
        return {
          ...item,
          checked: false
        }
      })
    }

    console.log('handleClearItem2', newState)

    // console.log('handleClearItem:', {item, newState, filters})

    setFilters(newState);
  }

  const getActiveModels = (cats, reset) => {
    let models = [];
    filterItems.brands.forEach(item => {
      const isChecked = cats.find(cat => (cat.value === item.value) && cat.checked === true)
      if (item.models && isChecked) {
        item.models.forEach(model => {
          const modStateCheckedIndex = findIndex(get(filters, 'models', []), {value: model.value});
          const modIsChecked = modStateCheckedIndex !== -1 ? filters.models[modStateCheckedIndex].checked : true;
          models.push({
            ...model,
            brand: item.value,
            checked: reset ? true : modIsChecked
          })
        })
      }
    })
    return models
  }
  
  // const handleClearItem = (type) => {
  //   if (props.onClearItem) {
  //     props.onClearItem(type);
  //   }
  // }

  const setFilters = (newState) => {
    props.onUpdate(newState);
  }

  const handleClear = (e) => {
    e.preventDefault();
    setInitialFilters();
  }

  useEffect(() => {
    if (filterItems && filterItems.models && filterItems.models.length > 0 && !state) {
      setInitialFilters();
    }
  }, [filterItems])

  // useEffect(() => {
    
  // }, [filters])

  useEffect(() => {
    state && updateFilters(state);
  }, [state])

  const activeBrands = get(filters, 'brands.vals', []).filter(item => item.checked);

  console.log('stock filter props', props, filters);

  return (
  	<div>
      {/* <Header>
        <Text variant="subtitle1" uppercase colour="charcoal">Refine Search</Text>
        <a href="#" onClick={handleClear}>Clear All</a>
      </Header> */}
      <AccordionItem>
        <Accordion title='Price' id="stock-price" size="small" activeBg="middleGrey" defaultExpanded={true} border={true} variant="secondary">

          <div>
            {filters.price &&
              <SliderWrapper key={filters.price.key}>  
                <Slider
                  title=" "
                  type="price"
                  defaultVals={get(filters, 'price.val', [20000, 100000])}
                  min={get(filters, 'price.min', 10000)}
                  max={get(filters, 'price.max', 100000)}
                  step={250}
                  inputSize="fullWidth"
                  onSliderChange={(val) => handleSliderChange('price', val)}
                />
              </SliderWrapper>
            }
          </div>
        </Accordion>
      </AccordionItem>
      {get(filters, 'types.vals', []).length > 2 &&
        <AccordionItem>
          <Accordion title='Type' id="stock-type" size="small" activeBg="middleGrey" defaultExpanded={true} border={true}>
            <div>
              <Deselect onClick={() => handleClearItem('types')}>Deselect All</Deselect>
              {get(filters, 'types.vals', []).map(item => (
                <CheckboxItem key={item.value}>
                  <Checkbox
                    checked={item.checked}
                    label={item.text}
                    onChange={(event) => handleFilterChange('types', item.value, event)}
                    name={`type_${item.value}`}
                  />
                </CheckboxItem>
              ))}
            </div>
          </Accordion>
        </AccordionItem>
      }
      <AccordionItem>
        <Accordion title='Category' id="stock-category" size="small" activeBg="middleGrey" defaultExpanded={false} border={true}>
          <div>
          <Deselect onClick={() => handleClearItem('categories')}>Deselect All</Deselect>

            {get(filters, 'categories.vals', []).map(item => (
              <CheckboxItem key={item.value}>
                <Checkbox
                  checked={item.checked}
                  label={item.text}
                  onChange={(event) => handleFilterChange('categories', item.value, event)}
                  name={`category_${item.value}`}
                />
              </CheckboxItem>
            ))}
          </div>
        </Accordion>
      </AccordionItem>
      <AccordionItem>
        <Accordion title='Brand' id="stock-brand" size="small" activeBg="middleGrey" defaultExpanded={false} border={true}>
          <div>
            <Deselect onClick={() => handleClearItem('brands')}>Deselect All</Deselect>

            {get(filters, 'brands.vals', []).map(item => (
              <CheckboxItem key={item.value}>
                <Checkbox
                  checked={item.checked}
                  label={item.text}
                  onChange={(event) => handleFilterChange('brands', item.value, event)}
                  name={`brand_${item.value}`}
                  // disabled={item.checked === true && activeBrands.length === 1}
                />
              </CheckboxItem>
            ))}
          </div>
        </Accordion>
      </AccordionItem>
      <AccordionItem>
        <Accordion title='Models' id="stock-models" size="small" activeBg="middleGrey" defaultExpanded={false} border={true}>
          <div>
          <Deselect onClick={() => handleClearItem('models')}>Deselect All</Deselect>

            {get(filters, 'models', []).map(item => (
              <CheckboxItem key={item.value}>
                <Checkbox
                  checked={item.checked}
                  label={item.text}
                  onChange={(event) => handleModelChange(item.value, event)}
                  name={`model_${item.value}`}
                />
              </CheckboxItem>
            ))}
          </div>
        </Accordion>
      </AccordionItem>
      <AccordionItem>
        <Accordion title='Year' id="stock-year" size="small" activeBg="middleGrey" defaultExpanded={false} border={true}>
          <div>
            {filters.year &&
              <SliderWrapper key={filters.year.key}>
                <Slider
                  title=" "
                  // type="price"
                  defaultVals={get(filters, 'year.val', [1992, 2020])}
                  min={get(filters, 'year.min', 1991)}
                  max={get(filters, 'year.max', 2020)}
                  step={1}
                  inputSize="fullWidth"
                  onSliderChange={(val) => handleSliderChange('year', val)}
                />
              </SliderWrapper>
            }
          </div>
        </Accordion>
      </AccordionItem>
      
    </div>
  )
}
  
StockFilter.propTypes = {
  onUpdate: propTypes.func
}
  
StockFilter.defaultProps = {
  onUpdate: () => {}
}
  
export default StockFilter