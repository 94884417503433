import React, {useState, useEffect, useRef} from 'react'
import propTypes from 'prop-types'
import {Grid, Box} from '@material-ui/core';
import styled from 'styled-components';
import {isWidthUp} from '@material-ui/core/withWidth';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {ReactSVG} from 'react-svg';
import {sortBy, uniqBy, findIndex, get} from 'lodash';
import {gql} from 'apollo-boost';
import qs from 'qs';

import jaycoClient from '../../../graphql/stockClient';
import withWidth from '../../../components/hoc/withWidth';
import {theme} from '../../../themes/default-theme';
import StockFilter from '../../StockFilter';
import filter from '../../../assets/icons/filter.svg';
import close from '../../../assets/icons/close.svg';
import Cta from '../../../components/ui/Cta';
import Icon from '../../../components/ui/Icon';
import StockCard from '../../../components/navigation/StockCard';
import Dropdown from '../../../components/ui/Dropdown';
import Text from '../../../components/typography';
import Pagination from '../../../components/navigation/Pagination';
import toPrice from '../../../util/toPrice';
import StockItem from '../../StockItem';
import Loader from '../../../components/ui/Loader';
import getUrlParams from '../../../util/getUrlParams'
import dlPush from '../../../util/dlPush';
import repaymentCalc from '../../../util/repaymentCalc';
import ContentBuilder from '../../ContentBuilder';
import Breadcrumbs from '../../../components/navigation/NewBreadcrumbs';
import topoPng from '../../../assets/topo-grey.png';

const customTheme = createTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        width: '100%',
        backgroundColor: theme.palette.white
      }
    },
    MuiBackdrop: {
      root: {
        display: 'none',
      }
    }
  }
});


const GridItem = styled(Grid)`
  
`

const Topo = styled.div`
    display: block;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    background-image: url(${topoPng});
    background-position: center;
    width: 100%;
    background-size: 100% auto;
    margin-bottom: 30px;

    ${theme.breakpoints.up("md")} {
      margin-bottom: 45px;
    }
`

const BreadcrumbsContainer = styled.div`
  margin-bottom: 20px;
  position: relative;
  margin-top: 20px;

  ${props => props.margin === 1 && `
    margin-top: 30px;
  `}

  ${theme.breakpoints.down('sm')} {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

const Wrapper = styled.div`
  ${props => props.bg && `
    background-color: #F3F4F7;
  `}

`

const GreyWrapper = styled.div`
  position: relative;
`

const GreyBg = styled.div`

  padding-top: 40px;

  ${theme.breakpoints.up('lg')} {
    padding-top: 0;
  }
`

const GreyTop = styled.div`
  ${theme.breakpoints.up('lg')} {
    height: 50px;
    width: 100%;
    display: block;
    border-radius: 0 50px 0 0;
    background: #F3F4F7;
    transform: rotate(-1deg);
    position: absolute;
    top: -30px;
    z-index: -1;
  }
`

const FilterContainer = styled.div`
  padding-bottom: 70px;
`

const CloseContainer = styled.div`
  text-align: center;
  padding: 7px;
  width: 100%;
  background-color: ${theme.palette.white};

  position: fixed;
  z-index: 99;
`

const Filters = styled.div`
  overflow-x: hidden;
  padding-top: 60px;
  padding-bottom: 30px;
  max-width: 480px;
  margin: 0 auto;

  ${theme.breakpoints.up('lg')} {
    padding-top: 30px;
  }
`

const FilterBtn = styled(props => {
  return <Cta {...props} />
})`
  display: block;
  margin: 0 auto;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.71px;
  line-height: 24px;
  max-width: 270px;

  svg {
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    top: 12px;
    left: 30px;
  }

  span {
    padding-left: 34px;
  }
`
const CaravanCardWrapper = styled(Box)`
  height: 100%;
`

const CaravanCardContainer = styled.div`
  margin: 0 auto 20px;
  height: 100%;
`

const Display = styled.div`
  ${theme.breakpoints.up('md')} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }
`

const DisplayItems = styled.div`

  margin-top: 15px;

  ${theme.breakpoints.up('md')} {
    margin-top: 0
  }
  
`

const DisplayDropdown = styled.div`
  width: 100%;
  max-width: 270px;
  margin: 0 auto;

  ${theme.breakpoints.up('sm')} {
    min-width: 305px;
    max-width: 100%;
    width: auto;
    margin: 0;
  }
`

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  button[disabled],
  button[disabled].MuiPaginationItem-root {
    opacity: 0.5 !important;
  }
`

const TitleContainer = styled.div`
  margin-bottom: 18px;

  h1 {
    text-align: center;
  }

  ${theme.breakpoints.up('lg')} {

    h1 {
      text-align: left;
    }
  }
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  button {
    min-width: 45%;
  }
`
const LeftPadding = styled.div`
  ${theme.breakpoints.up('lg')} {
      padding-left: 30px;
      padding-right: 30px;
  }
`

const StockContainer = (props) => {

  const activeModelParam = process.browser ? getUrlParams().model : get(props, 'router.query.model', null);
  const [activeModel, setActiveModel] = useState(activeModelParam);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [defaultPageTitle, setDefaultPageTitle] = useState(process.browser ? document.title : null);

  const [filterDefaults, setFilterDefaults] = useState(); // @todo replace with above
  const [dealerData, setDealerData] = useState(false);
  const [displayFinancePrice, setDisplayFinancePrice] = useState(true);

  const between = (x, min, max) => {
    return x >= min && x <= max;
  }

  const getYear = (date) => {
    const s = date.toString();
    return s.substr(s.length - 4);
  }

  const refactorUbsData = (data) => {
    return data.map((item, index) => {
      const images = item.images.split(',');
      const image = images[0]

      return {
        ...item,
        brand: item.make || null,
        category: item.variant,
        year: getYear(item.buildDate),
        id: item.id,
        type: item.type,
        name: item.model,
        price: item.driveAwayPrice,
        images,
        image,
        weeklyRepayment: (item.driveAwayPrice && item.driveAwayPrice.toString() !== '0') ? parseInt(repaymentCalc(props.rates, 52, item.driveAwayPrice)) : null,
        cta: {
          text: "Find out more",
          url: `/stock?model=${item.id}`
        }
      }
    })

  }

  const getStockFilterData = () => {

    const categories = uniqBy(allStock.map((item) => { return {value: item.category, text: item.category}}), 'value');

    let types = [];
    let brands = [];
    let models = [];
    let minPrice = 0;
    let maxPrice = 0;
    let minYear = null;
    let maxYear = null;

    allStock.forEach((item, index) => {



      // if item.type is not in type array
      const typeIndex = findIndex(types, {value: item.type})
      if (typeIndex === -1) {
        types.push({value: item.type, text: item.type})
      }

      // if item.brand is not in brands array
      const brandIndex = findIndex(brands, {value: item.brand})
      if (brandIndex === -1) {
        brands.push({value: item.brand, text: item.brand, models: []})
      }
      
      // if item.model is not in brands.model
      const bIndex = brandIndex === -1 ? (brands.length - 1) : brandIndex;
      const modelIndex = findIndex(models, {value: item.name});

      if (modelIndex === -1) {
        models.push({value: item.name, text: item.name, checked: true});
      }

      // price
      
      const price = parseInt(item.price);

      // if (minPrice === 0 && maxPrice === 0 && price !== 0) {
      if (index === 0) {
        minPrice = price;
        maxPrice = price;
      }

      if (price > maxPrice) {
        maxPrice = price;
      }
      // if (price < minPrice && price !== 0) {
      if (price < minPrice) {   
        minPrice = price;
      }

      // year
      const year = parseInt(item.year)
      if (!minYear || year < minYear) {
        minYear = year
      }
      if (!maxYear || year > maxYear) {
        maxYear = year
      }

      const brandModelIndex = findIndex(brands[bIndex].models, {value: item.name});
      if (brandModelIndex === -1) {
        brands[bIndex].models.push({value: item.name, text: item.name});
      }
    });

    const filterMaxPrice = maxPrice > 320000 ? maxPrice : 320000

    let stockFilter = {
      types: [{value: 'all', text: 'All'}, ...types],
      categories: [{value: 'all', text: 'All'}, ...categories],
      brands: [{value: 'all', text: 'All'}, ...brands],
      models,
      price: {min: minPrice, max: filterMaxPrice},
      year: {min: minYear, max: maxYear},
      key: Date.now()
    }

    const qsData = qs.parse(window.location.search.replace('?',''));

    if (qsData && qsData.search && qsData.search.type) {
      stockFilter = {
        ...stockFilter,
        types: stockFilter.types.map(item => {
          return {
            ...item,
            checked: item.value.toLowerCase() === qsData.search.type.toLowerCase() ? true : false
          }
        })
      }
    }

    if (qsData && qsData.search && qsData.search.series) {
      stockFilter = {
        ...stockFilter,
        models: stockFilter.models.map(item => {
          return {
            ...item,
            checked: item.value.toLowerCase() === qsData.search.series.toLowerCase() ? true : false
          }
        })
      }
    }

    console.log('stockFilterstockFilter', stockFilter, qsData);

    return stockFilter;
  }
  
  const numberPerPage = 15;
  const [drawer, setDrawer] = useState(false);
  const [sortByVal, setSortByVal] = useState({value: 'low'});
  const [stockItems, setStockItems] = useState([]);
  const [page, setPage] = useState(1);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(numberPerPage);
  const [stockFilterState, setStockFilterState] = useState(null);
  const [allStock, setAllStock] = useState([]);
  const [defaultFilters, setDefaultFilters] = useState({})

  const [stockFilterData, setStockFilterData] = useState(null)

  useEffect(() => {
    const filterData = getStockFilterData();
    setStockFilterData(filterData);
    setDefaultFilters(filterData);
  }, [allStock]);

  useEffect(() => {
    handlePaginationChange(null, 1, false);
  }, [stockItems])

  useEffect(() => {
    setDefaultPageTitle(document.title)
    const getData = async (dealerId) => {
      // if (!model) {

        setLoading(true);
        
        const GET_UBS_DATA = gql`
          query GetUBSStockForDealer {
            getDealer(id: "${dealerId}") {
              name
              ubsStock(limit: 1000) {
                items {
                  accessoryCodeList
                  accessoryDescList
                  askingPrice
                  body
                  branchId
                  buildDate
                  class
                  colour
                  complianceDate
                  createdAt
                  cylinders
                  driveAwayPrice
                  fuelType
                  gearbox
                  id
                  make
                  model
                  odometer
                  redbookCode
                  regoExpiryDate
                  regoNumber
                  regoState
                  seats
                  series
                  stockNumber
                  tare
                  trans
                  trimColour
                  type
                  updatedAt
                  variant
                  vehicleDescription
                  images
                  vin
                }
              }
            }
          }
        `

        const result = await jaycoClient.query({
          query: GET_UBS_DATA
        }) || {};

        const stock = get(result, 'data.getDealer.ubsStock.items', null)
        const data = stock ? refactorUbsData(stock) : null


        if (data) {
          setAllStock(data);
        }
        setError(null);
        setLoading(false);

        if (activeModelParam){
          setModelTitle(data);
        } 

      // }
    }

    const windowDealerId = process.env.DEALER_SITE && window.staticly.dealerData.id.indexOf('{{') === -1 ? window.staticly.dealerData.id : null
    const dealerId = windowDealerId ? windowDealerId : get(props, 'router.query.dealerid', null);

    if (dealerId) {
      getData(dealerId);
    } else {
      setLoading(false);
      setError('No dealer id set');
    }

  }, []);

  useEffect(() => {
    sortItems(allStock);
  },[allStock])

  const sortItems = (data) => {
    var items;

    const stock = data || stockItems
    
    // when sort is set to low then grab the items with price first
    const stockArr = (sortByVal && sortByVal.value && sortByVal.value === 'low') ? [
      ...sortBy(stock.filter(item => item.price && item.price > 0), ['price']),
      ...sortBy(stock.filter(item => !item.price || item.price === 0), ['price'])
    ] : stock


    if(!sortByVal) {
      items = sortBy(stock, ['name']);
    } else {
      switch(sortByVal.value) {
        case 'low':
          items = stockArr // sortBy(stock, ['price']);
          break;
        case 'high':
          items = sortBy(stock, ['price']).reverse();
          break;
        default:
          items = sortBy(stock, ['name']);
      }
    }

    setStockItems(items);
  }

  useEffect(() => {
    sortItems()
  }, [sortByVal])

  const handleUpdate = (filters) => {
    
    setStockFilterState(filters)

    // || get(filters, 'brands.vals[0].checked', false) === true 
    const filteredStock = allStock ? allStock.filter((item) => {
      // brands
      if(findIndex(get(filters, 'brands.vals', []), {value: item.brand, checked: true}) !== -1 ) {
        return item;
      }
    })
    .filter((item) => {
      // categories
      if(findIndex(get(filters, 'categories.vals', []), {value: item.category, checked: true}) !== -1) {
        return item;
      }
    })
    .filter((item) => {
      // models
      if(findIndex(get(filters, 'models', []), {value: item.name, checked: true}) !== -1) {
        return item;
      }
    })
    .filter((item) => {
      // types
      if(findIndex(get(filters, 'types.vals', []), {value: item.type, checked: true}) !== -1) {
        return item;
      }
    })
    .filter((item)=> {
      // year
      if(
        // get(item, 'year', 0) <= get(filters, 'price.min', 0)
        between(get(item, 'year', 0), get(filters, 'year.val[0]'), get(filters, 'year.val[1]'))
        || get(item, 'year', '').length === 0
      ) {
        return item;
      }
    })
    .filter((item)=> {
      // price min
      if(
        between(get(item, 'price', 0), get(filters, 'price.val[0]'), get(filters, 'price.val[1]'))
        // || get(item, 'price', 0) === 0
      ) {
        return item;
      }
    }) : null

    sortItems(filteredStock);
  }

  const handleClearItem = (item) => {

    const newState = {
      ...filters,
      [item]: filters[item].map(item => {
        return {
          ...item,
          checked: false
        }
      })
    }

    // console.log('handleClearItem:', {item, newState, filters})

    handleUpdate(newState);
  }

  // Pagination
  const stockDisplay = stockItems ? stockItems.slice(start,end) : [];
  const paginationLength = stockItems ? Math.ceil(stockItems.length / numberPerPage) : 0;

  const handlePaginationChange = (event, value, scroll) => {
    setPage(value);
    setStart(value === 1 ? 0 : (value - 1) * numberPerPage);
    setEnd(paginationLength === value ? stockItems.length : value * numberPerPage);

    if (scroll !== false) {
      const { top } = containerRef.current.getBoundingClientRect();
      const position = top + window.pageYOffset
      position ? window.scrollTo({
        top: position,
        behavior: 'smooth'
      }) : containerRef.current.scrollIntoView({ behavior: 'smooth'});
    }
  }

  const handleStockCardClick = (e, id) => {
    e && e.preventDefault();
  
    if (id) {
      window.history.pushState({
        model: id
      }, undefined, `/stock?model=${id}`);
      const title = setModelTitle(null, id, true);
      handleRouteChange(`/stock?model=${id}`, title)
    } else {
      window.history.pushState({
      }, undefined, `/stock`);
      document.title = defaultPageTitle;
      handleRouteChange(`/stock`, defaultPageTitle);
    }

    window.scrollTo(0,0);
    setActiveModel(id);
  }

  const setModels = (data) => {
    location.reload();
    // if (data.state.model) {
    //   setActiveModel(data.state.model);
    //   setModelTitle();
    // } else {
    //   setActiveModel(null);
    //   document.title = defaultPageTitle;
    // }
  };

  const setCanonical = () => {
    const pageCanonical = document.querySelectorAll('link[rel="canonical"]');
    pageCanonical && window.staticly && pageCanonical[0] && pageCanonical[0].setAttribute("href", 'https://www.' + window.staticly.dealerData.website + window.location.pathname + window.location.search);
  }

  const handleRouteChange = (url, title) => {
    dlPush({
      event: 'pageview',
      appHost: window.location.host,
      appPagepath: url,
      appPagetitle: title || document.title,
      appUrl: window.location.origin + (window.location.pathname !== '/' ? window.location.pathname : '')
    });
    setCanonical();
  }

  const setModelTitle = (data, id, get) => {
    const stock = data ? data : allStock;
    const _id = id ? id : activeModel
    const activeModelData = stock.find(item => (item.id === _id));

    if (!activeModelData) {
      return
    }

    const title = `${activeModelData.make} ${activeModelData.name} | ${window.staticly.dealerData.name}`;

    document.title = title

    if (get) {
      return title
    }

  }

  useEffect(() => {
    setCanonical();
    window.addEventListener('popstate', setModels);
    return () => {
      window.removeEventListener('popstate', setModels);
    }
  }, [])


  const containerRef = useRef();

  const browserTitle = (process.browser && window.staticly.data && window.staticly.data.pageTitle) ? window.staticly.data.pageTitle : props.meta.title

  const title = process.browser ? browserTitle : `
    {{# if data.template.pageTitle}}
      {{data.template.pageTitle}}
      {{else}}
        ${props.meta.title}
    {{/if}}
  `

  const windowData = `
    {{# if data.template.pageTitle}}
      <script>
          window.staticly.data.pageTitle = "{{data.template.pageTitle}}"
      </script>
    {{/if}}

    {{# if data.template.stockFilters}}
      <script>
          window.staticly.data.displayFinancePrice = "{{data.template.stockFilters.displayFinancePrice}}"
      </script>
    {{/if}}

    {{# if data.template.stockFilters.sort}}
      <script>
          window.staticly.data.sort = "{{data.template.stockFilters.sort}}"
      </script>
    {{/if}}
  `
  
  if (process.browser) {
    console.log('allStock', allStock)
  }

  const assignedDomains = ['jayco.com.au']

  const breadcrumbs = {
    list: [
      {
        link: {
            title: 'Home',
            href: '/',
            target: '_self',
            assignedDomains
        },
        active: false
      },
      {
        link: {
            title: 'Stock',
            href: '/stock',
            target: '_self',
            assignedDomains
        },
        active: true
      }
    ]
  };

  const handleBcClick = (e, link) => {
    if (link.href === window.location.pathname) {
      handleStockCardClick(e, null);
    }
  }

	useEffect(() => {
    // try {
    //   const component = document.getElementById('staticly-dynamics-component-stockFilters');
    //   // const properties = component ? component.getAttribute('data-component-properties') : null;
    //   // const jsonData  = properties ? JSON.parse(properties) : null;
    //   const jsonData = window.staticly.data && window.staticly.data.StockFilters ? window.staticly.data.StockFilters : null

    //   if (component && !jsonData) {
    //     component.remove();
    //     return;
    //   }

    //   if (jsonData && dealerComponent) {
    //     setFilterDefaults(jsonData.data);
        
    //     console.log('jsonData', jsonData);

    //     setSortByVal({
    //       value: jsonData.data.sort
    //     });
        
    //     setDisplayFinancePrice(jsonData.data.displayFinancePrice);

    //     setDealerData(true);
    //     component && component.remove();
    //   }
    // } catch {
      
    // }

    if (window.staticly && window.staticly.data) {
      if (window.staticly.data.sort) {
        setSortByVal({
          value: window.staticly.data.sort
        });
      }
      if (typeof(window.staticly.data.displayFinancePrice) !== 'undefined') {
        setDisplayFinancePrice(window.staticly.data.displayFinancePrice === 'false' ? false : true);
      }
    }

    // const qsData = qs.parse(window.location.search.replace('?',''));

    // // Only filter by type at this stage...
    // if (qsData && qsData.search) {
    //   setDefaultFilters(qsData.search)
    //   console.log('qsDataqsData', {qsData, stockFilterState, stockFilterData})
    // }


	}, []);

  console.log('filterDefaults', filterDefaults);
  console.log('displayFinancePrice', displayFinancePrice);
  console.log('sortByBal', sortByVal);

  // const filterString = `{{#staticlyDynamic data from="template.stockFilters" to="data" id="StockFilters" component="src/containers/Stock/Container/index.js"}}{{/staticlyDynamic}}`

  return (
  	<Wrapper ref={containerRef} bg={activeModel ? false : true}>

      <div dangerouslySetInnerHTML={{__html: windowData}}>
      </div>

      {/* {!process.browser && 
        <>
          <div dangerouslySetInnerHTML={{__html: filterString}}>
          </div>
        </>
      } */}

      {error ? 
        <>
          <Grid container className={'hasPadding'} direction={'row'} wrap="wrap">
            <Grid item xs={12}>
              <Box px={2}>
                <TitleContainer>
                  <Text variant="h6" colour="charcoal" align="center" uppercase>{error}</Text>
                </TitleContainer>
              </Box>
            </Grid>
          </Grid>
        </>
      :
      <>
        {!loading ?
        <>
          {activeModel ?
            <>
              <StockItem
                key={activeModel}
                // onGoBack={(e) => handleStockCardClick(e, null)}
                model={activeModel}
                allStock={allStock}
                onStockCardClick={handleStockCardClick}
                data={allStock.find(item => (item.id === activeModel))}
                calculatorModal={props.calculatorModal}
                dealerSuburbs={[]}
                breadcrumbs={breadcrumbs}
                onBreadcrumbClick={handleBcClick}
                displayFinancePrice={displayFinancePrice}
                />
            </>
          :
            <>

              {breadcrumbs && 
                <Topo>
                  <LeftPadding>
                    <Grid container className={'hasPadding'} direction={'row'} wrap="wrap">
                      <Grid item xs={12}>
                        <Box px={2}>
                          <BreadcrumbsContainer>
                            <Breadcrumbs items={breadcrumbs}/>
                          </BreadcrumbsContainer>
                        </Box>
                      </Grid>
                    </Grid>
                  </LeftPadding>
                </Topo>
              }

              <div>
                <ContentBuilder 
                  dealerCb={true}
                  // marginBottom={false} 
                  // topOfDealerPage={true} 
                />
              </div>
              <LeftPadding>
                <Grid container className={'hasPadding'} direction={'row'} wrap="wrap">
                  {isWidthUp('lg', props.width) && stockFilterData &&
                    <Grid item xs={12} lg={3}>
                      <Box px={2}>
                        <StockFilter
                          // key={stockFilterData.key}
                          filterItems={stockFilterData}
                          state={stockFilterState}
                          onUpdate={handleUpdate}
                          onClearItem={handleClearItem}
                          defaultFilters={defaultFilters}
                        />
                      </Box>
                    </Grid>
                  }
                  {!isWidthUp('lg', props.width) && stockFilterData &&
                    <>
                      <GridItem item xs={12}>
                        <Box px={2}>
                          <FilterBtn inline onClick={() => setDrawer(true)}>
                          <ReactSVG src={filter} />
                          <span>Filter and Search</span></FilterBtn>
                        </Box>
                      </GridItem>
                      <ThemeProvider theme={customTheme}>
                        <SwipeableDrawer
                          anchor="right"
                          open={drawer}
                          onClose={() => setDrawer(false)}
                          onOpen={() => setDrawer(true)}
                        >
                          <ThemeProvider theme={theme}>
                            <FilterContainer>
                              <CloseContainer>
                                <Icon onClick={() => setDrawer(false)} skyBlue>
                                  <ReactSVG src={close} />
                                </Icon>
                              </CloseContainer>
                              <Filters>
                                <Box px={2}>
                                  <StockFilter
                                    // key={stockFilterData.key}
                                    filterItems={stockFilterData}
                                    onUpdate={handleUpdate}
                                    state={stockFilterState}
                                    defaultFilters={defaultFilters}
                                  />
                                  <Buttons>
                                    <Cta inline onClick={() => setDrawer(false)} >Search</Cta>
                                    <Cta inline outline onClick={() => setDrawer(false)} >Cancel</Cta>
                                  </Buttons>
                                </Box>
                              </Filters>
                            </FilterContainer>
                          </ThemeProvider>
                        </SwipeableDrawer>
                      </ThemeProvider>
                    </>
                  }
                  <GridItem item xs={12} lg={9}>
                    <Box px={isWidthUp('lg', props.width) ? 2 : 0}>
                      <GreyWrapper>

                        <GreyBg>
                          <Box px={isWidthUp('md', props.width) ? 4 : 2}>                      
                            {get(props, 'meta.title', null) && !activeModel &&
                              <TitleContainer>
                                <Text variant="h2" element={"h1"} colour="charcoal" uppercase>{title}</Text>
                              </TitleContainer>
                            }
                            <Display>
                              <DisplayDropdown>
                                <Dropdown
                                  options={[{
                                      value: 'name',
                                      text: 'Product name (A-Z)'
                                    },{
                                      value: 'low',
                                      text: 'Sort by price (low to high)'
                                    },{
                                      value: 'high',
                                      text: 'Sort by price (high to low)'
                                    }
                                  ]} // because there's duplicate 'values'
                                  value={sortByVal}
                                  onChange={(e) => setSortByVal(e)}
                                />
                              </DisplayDropdown>
                              <DisplayItems>
                                <Text variant="body1" colour={'black'} align="center"> Displaying {stockDisplay ? stockDisplay.length : 0} of {allStock ? allStock.length : 0}</Text>
                              </DisplayItems>
                            </Display>
                          </Box>
                          <Box px={isWidthUp('lg', props.width) ? 2 : 1} py={4}>
                            {stockItems.length === 0 &&
                              <Grid container direction={'row'}>
                                <Grid item xs={12}>
                                  <Box px={2}>
                                    <Text variant="body1" colour="black">No stock. Please refine your search.</Text>
                                  </Box>
                                </Grid>
                              </Grid>
                            } 
                            <Grid container direction={'row'}>
    
                                {stockItems && stockItems.slice(start,end).map(item => (
                                  <Grid item xs={12} sm={6} lg={4} key={item.id}>
                                    <CaravanCardWrapper px={isWidthUp('lg', props.width) ? 2 : 1}>
                                      <CaravanCardContainer>
                                        <StockCard
                                          {...item}
                                          onClick={handleStockCardClick}
                                          price={item.price ? `$${toPrice(item.price)}` : null}
                                          weeklyRepayment={displayFinancePrice ? item.weeklyRepayment : null}
                                        />
                                      </CaravanCardContainer>
                                    </CaravanCardWrapper>
                                  </Grid>

                                ))}

                              {paginationLength > 1 &&
                                <Grid item xs={12}>
                                  <PaginationContainer>
                                    <Pagination
                                      page={page}
                                      onChange={handlePaginationChange}
                                      count={paginationLength}
                                    />
                                  </PaginationContainer>
                                </Grid>
                              }
                            </Grid>
                          </Box>
                        </GreyBg>
                      </GreyWrapper>
                    </Box>
                  </GridItem>
                </Grid>
              </LeftPadding>
            </>
          }

          {/* <ContentBuilder 
            data={pageData}
            handlebars={false}
          /> */}
        </>
        :
        <>
         <Grid container className={'hasPadding'} direction={'row'} wrap="wrap">
           <Grid item xs={12}>
             <Box px={2}>
               <Loader />
             </Box>
           </Grid>
         </Grid>
        </>
        }
      </>}

    </Wrapper>
   
  )
}
  
StockContainer.propTypes = {

}
  
StockContainer.defaultProps = {

}
  
export default withWidth(StockContainer)